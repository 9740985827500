<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="合同单号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="合同单号搜索"
                    class="namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="审批状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择审批状态"
                    class="statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in this.$store.state.state"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备案状态" prop="record_state">
                <el-select
                    v-model="form.record_state"
                    placeholder="选择备案状态"
                    class="record_state"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(item,index) in ['未备案','已备案']"
                        :key="index"
                        :label="item"
                        :value="index"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="履行状态" prop="perform_state">
                <el-select
                    v-model="form.perform_state"
                    placeholder="选择履行状态"
                    class="perform_state"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(item,index) in ['待定','正常','过期','否决','作废']"
                        :key="index"
                        :label="item"
                        :value="index"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="紧急状态" prop="urgent_state">
                <el-select
                    v-model="form.urgent_state"
                    placeholder="选择紧急状态"
                    class="urgent_statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(item,index) in AllUrgentState"
                        :key="index"
                        :label="item"
                        :value="index"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="主体数量" prop="subject_num">
                <el-select
                    v-model="form.subject_num"
                    placeholder="选择主体数量"
                    class="subject_numc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in AllSubjectNum"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合同类型" prop="contract_type">
                <el-select
                    v-model="form.contract_type"
                    placeholder="选择合同类型"
                    class="contract_type"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in AllContractType"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="业务类型" prop="business_type">
                <el-select
                    v-model="form.business_type"
                    placeholder="选择业务类型"
                    class="business_typec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in AllBusinessType"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>            
            <el-form-item label="负责人" prop="staff_id">
                <el-select
                    v-model="form.staff_id"
                    placeholder="选择负责人"
                    class="staff_idc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="staff in AllStaff"
                        :key="staff.name"
                        :label="staff.name"
                        :value="staff.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建人" prop="create_staff">
                <el-select
                    v-model="form.create_staff"
                    placeholder="选择创建"
                    class="create_staffc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="s in AllStaff"
                        :key="s.name"
                        :label="s.name"
                        :value="s.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合同主体" prop="client_name">
                <el-select
                    v-model="form.client_name"
                    placeholder="选择合同主体"
                    class="client_namec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="client in FilterAllClient"
                        :key="client.id"
                        :label="client.name"
                        :value="client.name"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备案月份起" prop="start_month">
                <el-date-picker
                    v-model="form.start_month"
                    align="right"
                    type="month"
                    placeholder="选择月份"
                    value-format="yyyy-MM"
                    class="start_monthc"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="备案月份止" prop="end_month">
                <el-date-picker
                    v-model="form.end_month"
                    align="right"
                    type="month"
                    placeholder="选择月份"
                    value-format="yyyy-MM"
                    class="end_monthc"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="到期截至日" prop="end_date">
                <el-date-picker
                    v-model="form.end_date"
                    align="right"
                    type="date"
                    placeholder="选择到期日"
                    value-format="yyyy-MM-dd"
                    class="end_datec"
                >
                </el-date-picker>
            </el-form-item>
            <div>
                <el-form-item label="我方企业" prop="enterprise_id">
                    <el-select
                        v-model="form.enterprise_id"
                        placeholder="选择我方企业"
                        class="enterprise_idc"
                        filterable
                        clearable
                        multiple
                        style="width: 700px;"
                    >
                        <el-option
                            v-for="enterprise in AllEnterprise"
                            :key="enterprise.id"
                            :label="enterprise.name"
                            :value="enterprise.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="form.daoqi" label="到期" border style="margin: 0;"><el-badge :value="daoqi_num" :max=999>即将到期</el-badge></el-checkbox>
                    <el-checkbox v-model="form.guoqi" label="过期" border style="margin-right: 10px;"><el-badge :value="guoqi_num" :max=999>已过期</el-badge></el-checkbox>
                    <el-button type="primary" plain @click="search">搜索</el-button>
                    <el-button type="warning" plain @click="resetbtn('searchform')">重置</el-button>
                    <el-button plain round @click="out_excel" v-if="$_has('导出合同')">导出</el-button>
                </el-form-item>
            </div>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加合同</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { staff_list_request } from '@/network/list.js'

export default {
    name: 'ContractSearchBarComponent',
    data() {
        return {
            form: {
                name: '',
                state: '',
                record_state: '',
                urgent_state: '',
                perform_state: '',
                subject_num: '',
                contract_type: '',
                business_type: '',
                staff_id: '',
                create_staff: '',
                enterprise_id: '',
                client_name: '',
                start_month: '',
                end_month: '',
                end_date: '',
                daoqi: false,
                guoqi: false,
                page: 1,
            },
            AllStaff: [],
            daoqi: false,
            guoqi: false
        }
    },
    computed: {
        FilterAllClient() {
            const arr = this.AllClient
            let name_arr = []
            return arr.filter((v) => {
                if(name_arr.indexOf(v.name) === -1) {
                    name_arr.push(v.name)
                    return v
                }
            })
        }
    },
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        out_excel() {
            this.$emit('out_excel', this.form)
        }
    },
    filters: {},
    props: {
        add_auth: String,
        AllEnterprise: Array,
        AllClient: Array,
        AllUrgentState: Array,
        AllBusinessType: Array,
        AllContractType: Array,
        AllSubjectNum: Array,
        daoqi_num:{
            type: String|Number,
            default() {
                return ''
            }
        },
        guoqi_num:{
            type: String|Number,
            default() {
                return ''
            }
        }
    },
    created() {
        this.get_all_staff()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>